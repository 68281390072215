import { AxiosInstance } from "@bps/http-client";

import {
  BpMobileCreateNotification,
  BpMobileMaintenanceSettings,
  BpMobileNotification,
  mapNotificationFromDto
} from "./bp-mobile.dtos";
import { IBpMobileOpsGateway } from "./BpMobileOpsGateway.interface";

export class BpMobileOpsGateway implements IBpMobileOpsGateway {
  constructor(private api: AxiosInstance) {}

  public async getMobileAppSettings(): Promise<BpMobileMaintenanceSettings> {
    const { data } = await this.api.get<BpMobileMaintenanceSettings>(
      "settings"
    );
    return data;
  }

  public async putMobileAppSettings(
    updates: BpMobileMaintenanceSettings
  ): Promise<BpMobileMaintenanceSettings> {
    const { data } = await this.api.put<BpMobileMaintenanceSettings>(
      "settings",
      updates
    );
    return data;
  }

  public async getMobileAppNotifications(): Promise<BpMobileNotification[]> {
    const { data } = await this.api.get<BpMobileCreateNotification[]>(
      "notifications"
    );

    const notifications = data.map(i => mapNotificationFromDto(i));

    return notifications;
  }

  public async putMobileAppNotifications(
    updates: BpMobileCreateNotification
  ): Promise<BpMobileNotification> {
    const { data } = await this.api.put<BpMobileCreateNotification>(
      "notifications",
      updates
    );
    return mapNotificationFromDto(data);
  }

  public async deleteMobileAppNotification(
    partitionKey: string,
    rowKey: string
  ): Promise<void> {
    await this.api.delete(`notifications/${partitionKey}/${rowKey}`);
  }
}
