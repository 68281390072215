import { formatISO } from "date-fns"; // Make sure to install date-fns if not already installed
import { useMutation, useQuery, useQueryClient } from "react-query";

import { useGateways } from "@libs/api/gateways-context";
import {
  BpMobileCreateNotification,
  BpMobileMaintenanceSettings,
  BpMobileNotification
} from "@libs/api/gateways/bp-mobile/bp-mobile.dtos";
import { useRootStore } from "@stores/StoresProvider";

const CacheKeys = {
  BpMobileAppSettings: "bpMobileAppSettings",
  BpMobileAppNotifications: "bpMobileAppNotifications"
};

export const useGetBpMobileAppSettings = () => {
  const { bpMobileOpsGateway } = useGateways();
  return useQuery<BpMobileMaintenanceSettings, Error>(
    CacheKeys.BpMobileAppSettings,
    () => bpMobileOpsGateway.getMobileAppSettings()
  );
};

export const usePutBpMobileAppSettings = () => {
  const { bpMobileOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation(
    async (updates: BpMobileMaintenanceSettings) => {
      // Add the current UTC date to the updates object
      const updatesWithDate = {
        ...updates,
        dateUtc: formatISO(new Date(), { representation: "complete" })
      };

      return bpMobileOpsGateway.putMobileAppSettings(updatesWithDate);
    },
    {
      onSuccess: response => {
        queryClient.setQueryData(CacheKeys.BpMobileAppSettings, response);
      },
      onError: (error: Error) => {
        throw error;
      }
    }
  );
};

export const useGetBpMobileAppNotifications = () => {
  const { bpMobileOpsGateway } = useGateways();
  return useQuery<BpMobileNotification[], Error>(
    CacheKeys.BpMobileAppNotifications,
    () => bpMobileOpsGateway.getMobileAppNotifications()
  );
};

export const useUpsertNotification = () => {
  const { bpMobileOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<BpMobileNotification, Error, BpMobileCreateNotification>(
    request => bpMobileOpsGateway.putMobileAppNotifications(request),
    {
      onSuccess: async updatedNotification => {
        const existingNotifications =
          queryClient.getQueryData<BpMobileNotification[]>([
            CacheKeys.BpMobileAppNotifications
          ]) || [];

        const notificationExists = existingNotifications.some(
          notification =>
            notification.partitionKey === updatedNotification.partitionKey &&
            notification.rowKey === updatedNotification.rowKey
        );

        let updatedNotifications;

        if (notificationExists) {
          updatedNotifications = existingNotifications.map(notification =>
            notification.partitionKey === updatedNotification.partitionKey &&
            notification.rowKey === updatedNotification.rowKey
              ? updatedNotification
              : notification
          );
          feedback.success("Updated notification successfully.");
        } else {
          updatedNotifications = [
            updatedNotification,
            ...existingNotifications
          ];
          feedback.success("Added new notification successfully.");
        }

        queryClient.setQueryData(
          [CacheKeys.BpMobileAppNotifications],
          updatedNotifications
        );
      },
      onError: async error => {
        feedback.error(error.message);
      }
    }
  );
};

export const useDeleteNotification = () => {
  const { bpMobileOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { partitionKey: string; rowKey: string }>(
    ({ partitionKey, rowKey }) =>
      bpMobileOpsGateway.deleteMobileAppNotification(partitionKey, rowKey),
    {
      onSuccess: async (_, { partitionKey, rowKey }) => {
        const existingNotifications =
          queryClient.getQueryData<BpMobileNotification[]>([
            CacheKeys.BpMobileAppNotifications
          ]) || [];

        const updatedNotifications = existingNotifications.filter(
          notification =>
            !(
              notification.partitionKey === partitionKey &&
              notification.rowKey === rowKey
            )
        );

        queryClient.setQueryData(
          [CacheKeys.BpMobileAppNotifications],
          updatedNotifications
        );

        feedback.success("Deleted notification successfully.");
      },
      onError: async error => {
        feedback.error(`Failed to delete notification: ${error.message}`);
      }
    }
  );
};
