import qs from "query-string";

import { AxiosInstance } from "@bps/http-client";
import { MIN_PAGE_ITEMS } from "@libs/api/types/headers.constants";
import { PagingTableStorageResponse } from "@libs/paging/paging-table-storage-response.type";

import {
  AddRedirectLinkCreatePayload,
  GetRedirectLinksArgs,
  RedirectLinkDto,
  RedirectLinkUsageDto,
  UpdateRedirectLinkCreatePayload
} from "./plt-redirect-gateway.dtos";
import { IPlatformRedirectGateway } from "./plt-redirect-gateway.interface";

export class PlatformRedirectGateway implements IPlatformRedirectGateway {
  constructor(private api: AxiosInstance) {}

  getRedirectLinkUsage = async (linkId: string, redirectUrl: string) => {
    const url = qs.stringifyUrl({
      url: "redirectLinkUsage",
      query: { linkId, redirectUrl }
    });

    return (await this.api.get<RedirectLinkUsageDto[]>(url)).data;
  };

  getAllRedirectLinks = async () => {
    return (await this.api.get<RedirectLinkDto[]>("redirectLink/all")).data;
  };

  getRedirectLinks = async (
    args: GetRedirectLinksArgs
  ): Promise<
    PagingTableStorageResponse<RedirectLinkDto, GetRedirectLinksArgs>
  > => {
    const url = qs.stringifyUrl({
      url: "redirectLink",
      query: {
        ...args,
        pageLength: MIN_PAGE_ITEMS
      }
    });

    const data = (await this.api.get(url)).data;
    return { results: data.list, args: data.args };
  };

  getRedirectLink = async (linkId: string) => {
    return (await this.api.get<RedirectLinkDto>(`redirectLink/${linkId}`)).data;
  };

  addRedirectLink = async (payload: AddRedirectLinkCreatePayload) => {
    return (await this.api.post<RedirectLinkDto>("redirectLink/", payload))
      .data;
  };

  updateRedirectLink = async (payload: UpdateRedirectLinkCreatePayload) => {
    return (await this.api.put<RedirectLinkDto>("redirectLink/", payload)).data;
  };

  deleteRedirectLink = async (linkId: string) => {
    return (await this.api.delete<void>(`redirectLink/${linkId}`)).data;
  };
}
