import { DateTime } from "@bps/utils";

export interface BpMobileMaintenanceSettings {
  forceUpgradeVersionNumber?: string;
  forceUpgradeMessage?: string;
  maintenanceMode?: boolean;
  enableFieldManagement?: boolean;
  maintenanceModeMessage?: string;
}

export interface BpMobileNotification {
  partitionKey?: string;
  rowKey?: string;
  startTime?: DateTime;
  endTime?: DateTime;
  outageMessage?: string;
  dateUtc?: string;
}

export interface BpMobileCreateNotification {
  partitionKey?: string;
  rowKey?: string;
  startTime?: string;
  endTime?: string;
  outageMessage?: string;
  dateUtc?: string;
}

export function mapNotificationFromDto(
  notification: BpMobileCreateNotification
) {
  return {
    ...notification,
    startTime: DateTime.fromISO(notification?.startTime!).setZone("utc"),
    endTime: DateTime.fromISO(notification.endTime!).setZone("utc")
  };
}
