import { ChangeLogDto, RefDataDto } from "../../types/common-dtos";

// This enumeration needs to align with the BpId definition
// https://dev.azure.com/bpcloud-dev/BpCloud/_git/Bps.Platform.BpId?path=/Bps.Platform.BpId.System.Business/Constants/SingInTypes.cs
export enum SignInTypeKeys {
  email = "emailAddress",
  federated = "federated"
}

// This enumeration needs to align with the BpId definition
// https://dev.azure.com/bpcloud-dev/BpCloud/_git/Bps.Platform.BpId?path=/Bps.Platform.BpId.System.Business/Constants/Issuers.cs
export enum IssuerType {
  bpCloudRemoteAuth = "bpcloud-remote-authn"
}

export interface MfaMethodDto {
  mfaMethod: string;
  name: string;
  value: string;
}

export interface BpIdUserDto {
  id: string;
  signInEmail: string;
  firstName: string;
  lastName: string;
  userPrincipalName: string;
  displayName: string;
  accountEnabled: boolean;
  createdDate?: string;
  creationType: string;
  deletedDate?: string;
  lastPasswordChangeDate?: string;
  lastPinCodeChangeDate?: string;
  userType: string;
  signInIdentities: {
    signInType: string;
    issuer: string;
    issuerAssignedId: string;
  }[];
  otherMails?: string[];
  mfaMethods?: MfaMethodDto[];
}

export interface BpIdUserSignInLogArgs {
  userId?: string;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  pageLength?: number;
  nextPageToken?: string;
}

export interface BpIdUserSignInLogStatus {
  additionalDetails: string;
  errorCode: number;
  failureReason: string;
}

export interface BpIdUserSignInLogDeviceDetail {
  browser: string;
  deviceId: string;
  displayName: string;
  isCompliant: boolean;
  isManaged: boolean;
  operatingSystem: string;
  trustType: string;
}

export interface BpIdUserSignInLog {
  id: string;
  appDisplayName: string;
  appId: string;
  clientAppUsed: string;
  correlationId: string;
  createdDateTime: string;
  ipAddress: string;
  resourceDisplayName: string;
  resourceId: string;
  userDisplayName: string;
  userId: string;
  userPrincipalName: string;
  status: BpIdUserSignInLogStatus;
  deviceDetail: BpIdUserSignInLogDeviceDetail;
}

export interface BpIdAuditLogArgs {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  pageLength?: number;
  nextPageToken?: string;
}

export interface BpIdAuditLog {
  id: string;
  activityDateTime: string;
  activityDisplayName: string;
  servicePrincipalName: string;
  category: string;
  correlationId: string;
  loggedByService: string;
  operationType: string;
  result: string;
  resultReason: string;
  additionalDetails: string;
}

export interface UserGroup {
  id: string;
  displayName: string;
  description: string;
  mailEnabled: boolean;
  securityIdentifier: string;
  securityEnabled: boolean;
  createdDateTime: string;
}

export interface UserGroupDto
  extends Omit<
    UserGroup,
    | "createdDateTime"
    | "securityIdentifier"
    | "securityEnabled"
    | "mailEnabled"
    | "description"
  > {}

export interface UserGroupSearch {
  groups: UserGroup[];
  tooManyResults: boolean;
}

export interface BpIdUsersSearchArgs {
  profileIdPrefix?: string;
  objectId?: string;
  givenNamePrefix?: string;
  surnamePrefix?: string;
  email?: string;
  mobile?: string;
  userPrincipalNamePrefix?: string;
}

export interface ResultsResponse<T> {
  records?: T[];
  tooManyResults?: boolean;
  hasError?: boolean;
  errorMessage?: string;
}

export interface UserInviteDto {
  id: string;
  code?: string;
  shortLinkId?: string;
  isCancelled?: boolean;
  redeemedOn?: string;
  fullUrl?: string;
  shortUrl?: string;
  changeLog?: ChangeLogDto;
  isValid?: boolean;
  tenantId?: string;
  tenantName?: string;
  userId?: string;
  expiresAfter?: string;
  firstName?: string;
  lastName?: string;
  signInEmail?: string;
  signInBpIdUserId?: string;
  issuer?: string;
  issuerAssignedId?: string;
  groups?: string[];
  signInType: string;
  hasPin?: boolean;
  redirectUrl?: string;
}

export interface UserInviteArgs
  extends Pick<
    UserInviteDto,
    | "userId"
    | "tenantId"
    | "signInBpIdUserId"
    | "expiresAfter"
    | "firstName"
    | "lastName"
    | "signInEmail"
    | "issuer"
    | "issuerAssignedId"
    | "groups"
    | "signInType"
    | "redirectUrl"
  > {
  generateShortLinkId?: boolean;
  useShortLinkPin?: boolean;
  shortLinkPin?: string;
}

export interface UserInviteResponse {
  id: string;
  pin: string;
}

export interface InvitationsArgs {
  tenantId?: string;
  userId?: string;
  name?: string;
  createdStartDate?: string;
  createdEndDate?: string;
  expiryStartDate?: string;
  expiryEndDate?: string;
  isValid?: boolean;
  // sorting arguments
  sortField?: "Firstname" | "ExpiresAfter" | "RedeemedOnDate";
  sortAscending?: boolean;
  // paging arguments
  limit?: number;
  pageParam?: number;
}

export interface CloudApplicationsRefDataDto extends RefDataDto {
  primaryUrl?: string;
  isMultiTenant?: boolean;
}
