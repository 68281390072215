import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import { UseQueryOptions } from "react-query/types/react/types";

import { HttpError, NotFoundError } from "@bps/http-client";
import { useGateways } from "@libs/api/gateways-context";
import { PagingTableStorageResponse } from "@libs/paging/paging-table-storage-response.type";
import { cacheDeleteSingleItemInArray } from "@libs/react-query/react-query-cache.utils";
import { useRootStore } from "@stores/StoresProvider";

import {
  AddRedirectLinkCreatePayload,
  GetRedirectLinksArgs,
  RedirectLinkDto,
  RedirectLinkUsageDto,
  UpdateRedirectLinkCreatePayload
} from "./plt-redirect-gateway.dtos";

enum PltRedirectCacheKeys {
  Links = "plt-redirect-links",
  UsedLinks = "plt-redirect-used-links"
}

const invalidateRedirectsLinks = async (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache().getAll();

  const redirectsQueries = queries.filter(s =>
    s.queryKey.includes(PltRedirectCacheKeys.Links)
  );

  if (redirectsQueries.length) {
    await Promise.all(
      redirectsQueries.map(query =>
        queryClient.invalidateQueries(query.queryKey)
      )
    );
  }
};

export const useUsedRedirectLinks = (
  linkId: string,
  redirectUrl?: string,
  options?: Omit<
    UseQueryOptions<RedirectLinkUsageDto[], HttpError>,
    "queryKey" | "queryFn"
  >
) => {
  const { pltRedirectOpsGateway } = useGateways();
  return useQuery<RedirectLinkUsageDto[], HttpError>(
    [PltRedirectCacheKeys.UsedLinks, linkId],
    () => pltRedirectOpsGateway.getRedirectLinkUsage(linkId, redirectUrl),
    {
      ...options,
      onError: e => {
        if (e instanceof NotFoundError) {
          return [];
        }
        return e;
      }
    }
  );
};

export const useRedirectLinks = (args: GetRedirectLinksArgs) => {
  const { pltRedirectOpsGateway } = useGateways();
  return useInfiniteQuery<
    PagingTableStorageResponse<RedirectLinkDto, GetRedirectLinksArgs>,
    HttpError
  >(
    [PltRedirectCacheKeys.Links, args],
    context => {
      return pltRedirectOpsGateway.getRedirectLinks({
        ...args,
        nextPageToken: context.pageParam
      });
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.args.nextPageToken;
      }
    }
  );
};

export const useRedirectLink = (
  linkId: string,
  options?: Omit<
    UseQueryOptions<RedirectLinkDto, HttpError>,
    "queryKey" | "queryFn"
  >
) => {
  const { pltRedirectOpsGateway } = useGateways();
  return useQuery<RedirectLinkDto, HttpError>(
    [PltRedirectCacheKeys.Links, linkId],
    () => pltRedirectOpsGateway.getRedirectLink(linkId),
    {
      ...options,
      onError: e => {
        if (e instanceof NotFoundError) {
          return undefined;
        }
        return e;
      }
    }
  );
};

export const useAddRedirectLink = () => {
  const { pltRedirectOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<RedirectLinkDto, HttpError, AddRedirectLinkCreatePayload>(
    pltRedirectOpsGateway.addRedirectLink,
    {
      onSuccess: () => {
        invalidateRedirectsLinks(queryClient);
        feedback.success("A new redirect link has been created!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useUpdatedRedirectLink = () => {
  const { pltRedirectOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    RedirectLinkDto,
    HttpError,
    UpdateRedirectLinkCreatePayload
  >(pltRedirectOpsGateway.updateRedirectLink, {
    onSuccess: () => {
      invalidateRedirectsLinks(queryClient);
      feedback.success("A new redirect link has been updated!");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useDeleteRedirectLink = () => {
  const { pltRedirectOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, string>(
    pltRedirectOpsGateway.deleteRedirectLink,
    {
      onSuccess: (data, linkId) => {
        cacheDeleteSingleItemInArray({
          queryClient,
          id: linkId,
          queryKey: [PltRedirectCacheKeys.Links],
          isIdentityEqual: (t: RedirectLinkDto) => t.linkId === linkId
        });

        feedback.success("A new redirect link has been deleted!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};
